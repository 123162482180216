.tree {
  margin: 0;
  padding: 0;
  list-style: none;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-inline-start: 1em;
    position: relative;

    ul {
      margin-inline-start: 0.5em;
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      position: absolute;
      inset-block-start: 0;
      inset-block-end: 0;
      inset-inline-start: -10px;
      border-inline-start: 1px solid #{$border};
    }
  }

  li {
    margin: 0;
    padding: 0.5em 1em 0.2em;
    line-height: 2em;
    color: #{$default-color};
    position: relative;
    border: 1px solid #{$border};
    margin: 10px 0;
    border-radius: 5px;
    line-height: 30px;

    i {
      margin-inline-end: 10px;
      font-weight: bold;
      font-size: 16px;

      /* padding-block-start: 10px; */
      line-height: 1;
    }
  }

  ul li {
    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 0;
      border-block-start: 2px solid #{$border};
      margin-block-start: -1px;
      position: absolute;
      inset-block-start: 22px;
      inset-inline-start: -10px;
    }

    &:last-child:before {
      background: transparent;
      height: auto;
      inset-block-start: 22px;
      inset-block-end: 0;
    }
  }
}

.indicator {
  margin-inline-end: 5px;
  color: #{$primary};
}

.tree li {
  a {
    text-decoration: none;
    color: #{$default-color};
    font-size: 15px;
  }

  button {
    text-decoration: none;
    color: #{$default-color};
    border: none;
    background: transparent;
    margin: 0;
    padding: 0;
    outline: 0;

    &:active,
    &:focus {
      text-decoration: none;
      color: #{$default-color};
      border: none;
      background: transparent;
      margin: 0;
      padding: 0;
      outline: 0;
    }
  }

  &.branch {
    background: #{$white};

    li {
      background: #{$white};
    }
  }
}

.tree li i {
  display: inline-block;
}