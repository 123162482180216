.ff_fileupload_hidden {
	display: none;
}

.ff_fileupload_wrap {
	.ff_fileupload_dropzone_wrap {
		position: relative;
	}

	.ff_fileupload_dropzone {
		display: block;
		width: 100%;
		height: 100px;
		box-sizing: border-box;
		border: 2px dashed $border;
		border-radius: 5px;
		padding: 0;
		background-color: #f6f6fb;
		background-image: url("../plugins/fancyuploder/fancy_upload.png");
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0.85;
		cursor: pointer;
		outline: none;

		&::-moz-focus-inner {
			border: 0;
		}

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
			background-color: $white;
			border-color: #f3f4f7;
		}
	}

	.ff_fileupload_dropzone_tools {
		position: absolute;
		inset-inline-end: 10px;
		inset-block-start: 0;
	}

	.ff_fileupload_dropzone_tool {
		display: block;
		margin-block-start: 10px;
		width: 40px;
		height: 40px;
		box-sizing: border-box;
		border: 1px solid #efecec;
		border-radius: 3px;
		padding: 0;
		background-color: #fdfdfd;
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 0.85;
		cursor: pointer;
		outline: none;

		&::-moz-focus-inner {
			border: 0;
		}

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
			background-color: $white;
			border-color: #4e4c73;
		}
	}

	.ff_fileupload_recordaudio {
		background-image: url("../plugins/fancyuploder/fancy_microphone.png");
	}

	.ff_fileupload_recordvideo {
		background-image: url("../plugins/fancyuploder/fancy_webcam.png");
	}

	.ff_fileupload_recordvideo_preview {
		position: absolute;
		display: block;
		inset-inline-end: 60px;
		inset-block-start: 10px;
		width: 320px;
		max-width: calc(100% - 70px);
		height: calc(100% - 20px);
		background-color: #222222;

		&.ff_fileupload_hidden {
			display: none;
		}
	}

	.ff_fileupload_recording {
		animation: ff_fileupload_recording_animate 1.2s infinite alternate;
	}

	table.ff_fileupload_uploads {
		width: 100%;
		border-collapse: collapse !important;
		border: 0 none;

		tr {
			margin: 0;
			border: 0 none;
			padding: 0;
		}

		td {
			margin: 0;
			border: 0 none;
			padding: 0;
			vertical-align: top;
			padding: 1em 0;
			white-space: nowrap;
			line-height: normal;
		}

		tr.ff_fileupload_bounce {
			animation: ff_fileupload_bounce_animate 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
			transform: translateY(0);
		}

		td {
			&.ff_fileupload_preview {
				width: 1px;

				.ff_fileupload_preview_image {
					display: block;
					box-sizing: border-box;
					border: 0 none;
					padding: 0;
					background-color: #f3f4f7;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					width: 50px;
					height: 50px;
					border-radius: 5px;
					opacity: 0.75;
					text-align: center;
					font-size: 12px;
					font-weight: bold;
					overflow: hidden;
					outline: none;
					cursor: default;

					&::-moz-focus-inner {
						border: 0;
					}
				}

				.ff_fileupload_preview_image_has_preview {
					cursor: pointer;
				}

				.ff_fileupload_preview_image {
					&:hover,
					&:focus,
					&:active {
						opacity: 1;
					}
				}

				.ff_fileupload_preview_text {
					display: block;
					margin: 0 auto;
					width: 70%;
					overflow: hidden;
				}
			}

			&.ff_fileupload_actions button {
				display: inline-block;
				vertical-align: top;
				width: 26px;
				height: 26px;
				box-sizing: border-box;
				border: 1px solid $border;
				border-radius: 3px;
				padding: 0;
				background-color: #f6f6fb;
				background-repeat: no-repeat;
				background-position: center center;
				opacity: 0.85;
				cursor: pointer;
				outline: none;

				&::-moz-focus-inner {
					border: 0;
				}

				&:hover,
				&:focus,
				&:active {
					opacity: 1;
					background-color: #fdfdfd;
					border-color: $border;
				}

				&.ff_fileupload_start_upload {
					margin-inline-end: 0.5em;
				}
			}

			&.ff_fileupload_preview .ff_fileupload_actions_mobile {
				display: none;

				button {
					display: block;
					margin-block-start: 0.3em;
					width: 100%;
					height: 28px;
					box-sizing: border-box;
					border: 1px solid #efecec;
					border-radius: 3px;
					padding: 0;
					background-color: #f3f3f3;
					background-repeat: no-repeat;
					background-position: center center;
					opacity: 0.85;
					cursor: pointer;
					outline: none;

					&::-moz-focus-inner {
						border: 0;
					}

					&:hover,
					&:focus,
					&:active {
						opacity: 1;
						background-color: #fdfdfd;
						border-color: #4e4c73;
					}
				}
			}
		}

		button {
			&.ff_fileupload_start_upload {
				background-image: url("../plugins/fancyuploder/fancy_okay.png");
			}

			&.ff_fileupload_remove_file {
				background-image: url("../plugins/fancyuploder/fancy_remove.png");
			}
		}

		td {
			&.ff_fileupload_preview {
				.ff_fileupload_preview_text_with_color {
					color: $white;
				}

				.ff_fileupload_preview_text_a {
					background-color: #f03c3c;
				}

				.ff_fileupload_preview_text_b {
					background-color: #f05a3c;
				}

				.ff_fileupload_preview_text_c {
					background-color: #f0783c;
				}

				.ff_fileupload_preview_text_d {
					background-color: #f0963c;
				}

				.ff_fileupload_preview_text_e {
					background-color: #e0862b;
				}

				.ff_fileupload_preview_text_f {
					background-color: #dca12b;
				}

				.ff_fileupload_preview_text_g {
					background-color: #c7ab1e;
				}

				.ff_fileupload_preview_text_h {
					background-color: #c7c71e;
				}

				.ff_fileupload_preview_text_i {
					background-color: #abc71e;
				}

				.ff_fileupload_preview_text_j {
					background-color: #8fc71e;
				}

				.ff_fileupload_preview_text_k {
					background-color: #72c71e;
				}

				.ff_fileupload_preview_text_l {
					background-color: #56c71e;
				}

				.ff_fileupload_preview_text_m {
					background-color: #3ac71e;
				}

				.ff_fileupload_preview_text_n {
					background-color: #1ec71e;
				}

				.ff_fileupload_preview_text_o {
					background-color: #1ec73a;
				}

				.ff_fileupload_preview_text_p {
					background-color: #1ec756;
				}

				.ff_fileupload_preview_text_q {
					background-color: #1ec78f;
				}

				.ff_fileupload_preview_text_r {
					background-color: #1ec7ab;
				}

				.ff_fileupload_preview_text_s {
					background-color: #1ec7c7;
				}

				.ff_fileupload_preview_text_t {
					background-color: #1eabc7;
				}

				.ff_fileupload_preview_text_u {
					background-color: #1e8fc7;
				}

				.ff_fileupload_preview_text_v {
					background-color: #1e72c7;
				}

				.ff_fileupload_preview_text_w {
					background-color: #3c78f0;
				}

				.ff_fileupload_preview_text_x {
					background-color: #3c5af0;
				}

				.ff_fileupload_preview_text_y {
					background-color: #3c3cf0;
				}

				.ff_fileupload_preview_text_z {
					background-color: #5a3cf0;
				}

				.ff_fileupload_preview_text_0 {
					background-color: #783cf0;
				}

				.ff_fileupload_preview_text_1 {
					background-color: #963cf0;
				}

				.ff_fileupload_preview_text_2 {
					background-color: #b43cf0;
				}

				.ff_fileupload_preview_text_3 {
					background-color: #d23cf0;
				}

				.ff_fileupload_preview_text_4 {
					background-color: #f03cf0;
				}

				.ff_fileupload_preview_text_5 {
					background-color: #f03cd2;
				}

				.ff_fileupload_preview_text_6 {
					background-color: #f03cb4;
				}

				.ff_fileupload_preview_text_7 {
					background-color: #f03c96;
				}

				.ff_fileupload_preview_text_8 {
					background-color: #f03c78;
				}

				.ff_fileupload_preview_text_9 {
					background-color: #f03c5a;
				}
			}

			&.ff_fileupload_summary {
				padding: 1em;
				font-size: 0.9em;
				white-space: normal;
				color: #7f88a5;

				.ff_fileupload_filename {
					width: 100%;
					font-weight: bold;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					input {
						box-sizing: border-box;
						width: 100%;
						padding: 0.5em 0;
						margin-block-end: 0.1em;
						font-size: 1em;
						font-weight: normal;
						line-height: normal;
						border: 0px solid #e8ebf3;
						border-radius: 0;
						box-shadow: none;

						/* color: #080e1b; */
						font-weight: 600;

						&:focus,
						&:hover {
							border: 0px solid #e8ebf3;
						}
					}
				}

				.ff_fileupload_errors {
					color: #a94442;
					font-weight: bold;
				}

				.ff_fileupload_progress_background {
					margin-block-start: 0.5em;
					background-color: #cccccc;
					height: 2px;
				}

				.ff_fileupload_progress_bar {
					background-color: #4e4c73;
					width: 0;
					height: 2px;
				}
			}

			&.ff_fileupload_actions {
				width: 1px;
				text-align: end;
			}
		}
	}
}

@keyframes ff_fileupload_recording_animate {
	from {
		border-color: #ef1f1f;
	}

	to {
		border-color: #c9a1a1;
	}
}

@keyframes ff_fileupload_bounce_animate {
	10%,
	90% {
		transform: translateY(-1px);
	}

	20%,
	80% {
		transform: translateY(2px);
	}

	30%,
	50%,
	70% {
		transform: translateY(-3px);
	}

	40%,
	60% {
		transform: translateY(3px);
	}
}

/* Colored buttons based on file extension for non-images. */

@media (max-width: 420px) {
	.ff_fileupload_wrap table.ff_fileupload_uploads td {
		&.ff_fileupload_preview .ff_fileupload_preview_image {
			width: 36px;
			height: 36px;
			font-size: 11px;
		}

		&.ff_fileupload_summary {
			padding-inline-end: 0;
		}

		&.ff_fileupload_actions {
			display: none;
		}

		&.ff_fileupload_preview .ff_fileupload_actions_mobile {
			display: block;
		}
	}
}

.ff_fileupload_dialog_background {
	position: fixed;
	inset-block-start: 0;
	inset-inline-start: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.85);
	z-index: 10000;
}

.ff_fileupload_dialog_main {
	position: absolute;
	inset-block-start: 10%;
	inset-inline-start: 10%;
	width: 80%;
	height: 80%;
	text-align: center;

	img {
		position: relative;
		inset-block-start: 50%;
		transform: perspective(1px) translateY(-50%);
		max-width: 100%;
		max-height: 100%;
	}

	audio {
		position: relative;
		inset-block-start: 50%;
		transform: perspective(1px) translateY(-50%);
		width: 100%;
	}

	video {
		position: relative;
		inset-block-start: 50%;
		transform: perspective(1px) translateY(-50%);
		max-width: 100%;
		max-height: 100%;
	}
}
