:root {
    --sm-background:#f2f3f9;
    --sm-default-color:#25252a;
    --sm-default-border:#eaedf1;
	--sm-primary-rgb: 94, 45, 216;
	--sm-primary-hover: #561fdf;
	--sm-primary-border: #5e2dd8;
    --sm-primary-01:  rgba(var(--sm-primary-rgb), 0.1);
    --sm-primary-02:  rgba(var(--sm-primary-rgb), 0.2);
    --sm-primary-03:  rgba(var(--sm-primary-rgb), 0.3);
    --sm-primary-04:  rgba(var(--sm-primary-rgb), 0.4);
    --sm-primary-05:  rgba(var(--sm-primary-rgb), 0.5);
    --sm-primary-06:  rgba(var(--sm-primary-rgb), 0.6);
    --sm-primary-07:  rgba(var(--sm-primary-rgb), 0.7);
    --sm-primary-005:  rgba(var(--sm-primary-rgb), 0.05);
    --sm-primary-09:  rgba(var(--sm-primary-rgb), 0.7);
	--sm-secondary-rgb: 212, 63, 141;
	--sm-secondary-01: rgba(212, 63, 141,0.1);
    --sm-secondary-02: rgba(212, 63, 141,0.2);
	--sm-success-rgb: 9, 173, 149;
	--sm-info-rgb: 7, 116, 248;
	--sm-warning-rgb: 247, 183, 49;
	--sm-danger-rgb: 248, 38, 73;
	--sm-light-rgb: 242, 242, 249;
	--sm-dark-rgb: 52, 58, 64;
	--sm-blue-rgb: 50, 35, 241;
	--sm-indigo-rgb: 101, 116, 205;
	--sm-purple-rgb: 137, 39, 236;
	--sm-pink-rgb: 252, 82, 150;
	--sm-red-rgb: 231, 56, 39;
	--sm-orange-rgb: 252, 115, 3;
	--sm-yellow-rgb: 251, 176, 52;
	--sm-lime-rgb: 118, 219, 35;
	--sm-green-rgb: 78, 204, 72;
	--sm-teal-rgb: 28, 175, 159;
	--sm-cyan-rgb: 0, 126, 167;
	--sm-azure-rgb: 69, 170, 242;
	--sm-white-rgb: 255, 255, 255;
	--sm-fixed-white-rgb: 255, 255, 255;
	--sm-black-rgb: 0, 0, 0;
	--sm-gray-rgb: 90, 105, 112;
	--sm-muted-rgb: 118, 131, 154;
	--sm-dark-background:#22223d;
	--sm-dark-theme:#30304d;
	--sm-dark-border:rgba(255,255,255,0.1);
	--float-right: right;
    --float-left: left;
}

$background: var(--sm-background);
$default-color:var(--sm-default-color);
$border: var(--sm-default-border);

/*Color variables*/
$primary:rgb(var(--sm-primary-rgb)); 
$primary-hover:var(--sm-primary-hover); 
$primary-border:var(--sm-primary-border); 
$primary-01:var(--sm-primary-01);
$primary-02:var(--sm-primary-02);
$primary-03:var(--sm-primary-03);
$primary-05:var(--sm-primary-05);
$primary-06:var(--sm-primary-06);
$primary-005:var(--sm-primary-005);
$primary-09:var(--sm-primary-09);
$secondary:rgb(var(--sm-secondary-rgb));
$secondary-01:var(--sm-secondary-01);
$secondary-02:var(--sm-secondary-02);
$pink:rgb(var(--sm-pink-rgb));
$teal:rgb(var(--sm-teal-rgb));
$purple:rgb(var(--sm-purple-rgb));
$success:rgb(var(--sm-success-rgb));
$warning:rgb(var(--sm-warning-rgb));
$danger:rgb(var(--sm-danger-rgb));
$info:rgb(var(--sm-info-rgb));
$orange:rgb(var(--sm-orange-rgb));
$red:rgb(var(--sm-red-rgb));
$lime:rgb(var(--sm-lime-rgb));
$dark:rgb(var(--sm-dark-rgb));
$indigo:rgb(var(--sm-indigo-rgb));
$cyan:rgb(var(--sm-cyan-rgb));
$azure:rgb(var(--sm-azure-rgb));
$white:rgb(var(--sm-white-rgb));
$fixed-white:rgb(var(--sm-fixed-white-rgb));
$black:rgb(var(--sm-black-rgb));
$light:rgb(var(--sm-light-rgb));
$gray:rgb(var(--sm-gray-rgb));
$muted:rgb(var(--sm-muted-rgb));
$green:rgb(var(--sm-green-rgb));
$blue:rgb(var(--sm-blue-rgb));
$yellow:rgb(var(--sm-yellow-rgb));


/*Gradient variables*/
$primary-gradient-1:linear-gradient(to right, #5e2dd8  0%, #6d5df1 100%); 
$primary-gradient-2:linear-gradient(to right, #d52b46 0%, #bf4959 100%); 
$primary-gradient-3:linear-gradient(to right, #569834 0%, #627955 100%); 
$primary-gradient-4:linear-gradient(to right, #4453fb 0%, #222a88 100%); 
$primary-gradient-5:linear-gradient(to right, #f3532f 0%, #8a6f58 100%); 
$secondary-gradient:linear-gradient(to bottom right, #d43f8d 0% , #af4666 100%);
$warning-gradient:linear-gradient(to bottom right,#f66b4e 0%, #fbc434 100%);
$info-gradient:linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%);
$danger-gradient:linear-gradient(to bottom right, #FF416C 0%, #FF4B2B 100%);
$success-gradient:linear-gradient(to bottom right, #21a544 0% , #62fb62 100%);

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4:rgba(255, 255, 255, 0.4);
$white-5:rgba(255, 255, 255, 0.5);
$white-6:rgba(255, 255, 255, 0.6);
$white-7:rgba(255, 255, 255, 0.7);
$white-8:rgba(255, 255, 255, 0.8);
$white-9:rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);


/*float variables*/
$float-end: var(--float-right);
$float-start: var(--float-left);

/*gray variables*/
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

/*shadow variables*/
$shadow:0 5px 15px 5px rgba(80, 102, 224, 0.08);
$dark-background:var(--sm-dark-background);
$dark-theme-color:var(--sm-dark-theme);
$dark-border:var(--sm-dark-border);
$dark-color:#dedefd;
$dark-theme:#1e2448;
$dark-theme2:#16192f;
$dark-theme3:#181d3e;

/*skins variables*/
$background-1:#30304d;

/*Transparent variables*/
$transparent-primary:$primary;
$transparent-theme:rgba(0, 0, 0, 0.2);
$transparent-border:rgba(255, 255, 255, 0.1);