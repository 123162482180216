/* jQuery Growl
 * Copyright 2015 Kevin Sylvestre
 * 1.3.5
 */

.ontop,
#growls-default,
#growls-tl,
#growls-tr,
#growls-bl,
#growls-br,
#growls-tc,
#growls-bc,
#growls-cc,
#growls-cl,
#growls-cr {
    z-index: 50000;
    position: fixed;
}

#growls-default {
    inset-block-start: 10px;
    inset-inline-end: 10px;
}

#growls-tl {
    inset-block-start: 10px;
    inset-inline-start: 10px;
}

#growls-tr {
    inset-block-start: 10px;
    inset-inline-end: 10px;
}

#growls-bl {
    inset-block-end: 10px;
    inset-inline-start: 10px;
}

#growls-br {
    inset-block-end: 10px;
    inset-inline-end: 10px;
}

#growls-tc {
    inset-block-start: 10px;
    inset-inline-end: 10px;
    inset-inline-start: 10px;
}

#growls-bc {
    inset-block-end: 10px;
    inset-inline-end: 10px;
    inset-inline-start: 10px;
}

#growls-cc {
    inset-block-start: 50%;
    inset-inline-start: 50%;
    margin-inline-start: -125px;
}

#growls-cl {
    inset-block-start: 50%;
    inset-inline-start: 10px;
}

#growls-cr {
    inset-block-start: 50%;
    inset-inline-end: 10px;
}

#growls-tc .growl,
#growls-bc .growl {
    margin-inline-start: auto;
    margin-inline-end: auto;
}

.growl {
    opacity: 0.9;
    filter: alpha(opacity = 80);
    position: relative;
    border-radius: 4px;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;

    &.growl-incoming,
    &.growl-outgoing {
        opacity: 0;
        filter: alpha(opacity = 0);
    }

    &.growl-small {
        width: 200px;
        padding: 5px;
        margin: 5px;
    }

    &.growl-medium {
        width: 250px;
        padding: 10px;
        margin: 10px;
    }

    &.growl-large {
        width: 300px;
        padding: 15px;
        margin: 15px;
    }

    &.growl-default {
        color: $white;
        background: linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%);
    }

    &.growl-error {
        color: $white;
        background: #ff5b51;
    }

    &.growl-error1 {
        color: $white;
        background: linear-gradient(to bottom right, #b51b35 0%, #fd4a68 100%);
    }

    &.growl-notice {
        color: $white;
        background: #2dce89;
    }

    &.growl-notice1 {
        color: $white;
        background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%);
    }

    &.growl-sucess {
        color: $white;
        background: #2dce89;
    }

    &.growl-warning {
        color: $white;
        background: #ecb403;
    }

    &.growl-warning1 {
        color: $white;
        background: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%);
    }

    .growl-close {
        cursor: pointer;
        float: $float-end;
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
        font-family: helvetica, verdana, sans-serif;
    }

    .growl-title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        margin-block-end: 5px;
    }

    .growl-message {
        font-size: 13px;
        line-height: 18px;
    }
}
