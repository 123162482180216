// FROALA EDITOR

.fr-toolbar,
.fr-second-toolbar,
.fr-box.fr-basic .fr-wrapper {
    border-color: $border !important;
}

.fr-qi-helper a.fr-btn.fr-floating-btn {
    padding: 10px !important;
}

.fr-box a.fr-floating-btn {
    line-height: 0 !important;
}

.fr-toolbar .fr-more-toolbar {
    background-color: #edeef4 !important;
}

.fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
    background-color: #edeef4 !important;
}

.fr-desktop .fr-command:hover:not(.fr-table-cell),
.fr-desktop .fr-command:focus:not(.fr-table-cell),
.fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
.fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
    background: #edeef4 !important;
}

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
    background: #edeef4 !important;
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
    background: #edeef4 !important;
}

.fr-markdown-editor {
    background: #edeef4 !important;
}

.fr-quick-insert {
    z-index: 1 !important;
}

.fr-popup {
    z-index: 99 !important;
}

#fr-logo:hover > span,
#fr-logo:focus > span,
.fr-trim-button {
    color: $primary !important;
}

#fr-logo:hover>svg .fr-logo, #fr-logo:focus>svg .fr-logo{
    fill: $primary !important;
}

.fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path{
    fill: $primary !important;
}

@media (max-width:768px){
    .fr-markdown-view{
        width: 100% !important;
    }
}

.dark-mode {
    .fr-toolbar,
    .fr-box.fr-basic .fr-wrapper,
    .fr-second-toolbar {
        background-color: $dark-background !important;
    }
    .fr-toolbar .fr-command.fr-btn svg path,
    .fr-popup .fr-command.fr-btn svg path,
    .fr-modal .fr-command.fr-btn svg path {
        fill: $dark-color !important;
    }
    .fr-toolbar,
    .fr-second-toolbar,
    .fr-box.fr-basic .fr-wrapper {
        border-color: $dark-border !important;
    }
    .fr-desktop .fr-command:hover:not(.fr-table-cell),
    .fr-desktop .fr-command:focus:not(.fr-table-cell),
    .fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
    .fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
        background-color: $dark-theme !important;
    }
    .fr-toolbar .fr-more-toolbar {
        background-color: $dark-theme !important;
    }
    .fr-box.fr-basic .fr-element {
        color: $dark-color !important;
    }
    .fr-command.fr-btn + .fr-dropdown-menu {
        background-color: $dark-background !important;
    }
    .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
        background-color: $dark-theme !important;
    }
    .fr-toolbar {
        color: $dark-color;
    }
    .fr-command.fr-btn
        + .fr-dropdown-menu
        .fr-dropdown-wrapper
        .fr-dropdown-content
        ul.fr-dropdown-list
        li
        a.fr-active {
        background-color: $dark-theme !important;
    }
    .fr-popup {
        background-color: $dark-theme !important;
        color: $dark-color;
    }
    .fr-trim-button,
    .fr-popup .fr-buttons.fr-tabs,
    .fr-popup .fr-files-upload-layer:hover {
        background-color: $dark-theme !important;
        color: $primary;
    }
    .fr-toolbar .fr-newline {
        background-color: $dark-border;
    }
    .fr-toolbar .fr-command.fr-btn.fr-btn-active-popup,
    .fr-popup .fr-command.fr-btn.fr-btn-active-popup,
    .fr-modal .fr-command.fr-btn.fr-btn-active-popup {
        background-color: $dark-background;
    }
    .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
    .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
    .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
        background-color: $dark-background;
    }
    .fr-popup .fr-input-line input[type="text"],
    .fr-popup .fr-input-line input[type="number"],
    .fr-popup .fr-input-line textarea {
        border-color: $dark-border;
        background-color: $dark-theme;
        color: $dark-color;
    }
    .fr-popup .fr-input-line input + label,
    .fr-popup .fr-input-line textarea + label {
        color: $dark-color;
        background-color: $dark-theme;
    }
    .fr-popup .fr-action-buttons button.fr-command {
        color: $primary;
    }
    .fr-popup .fr-input-line input[type="text"]:focus,
    .fr-popup .fr-input-line input[type="number"]:focus,
    .fr-popup .fr-input-line textarea:focus {
        border-color: $dark-border;
    }
    .fr-qi-helper a.fr-btn.fr-floating-btn {
        background-color: $dark-theme;
    }
    .fr-qi-helper a.fr-btn.fr-floating-btn svg {
        fill: $dark-color;
    }
    .fr-view table td,
    .fr-view table th {
        border-color: $dark-border;
    }
    .fr-element table td.fr-selected-cell,
    .fr-element table th.fr-selected-cell {
        border-color: $primary;
    }
    .fr-separator {
        background-color: $dark-border;
    }
    .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
    .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
    .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
        background-color: $dark-background !important;
    }
    .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a svg path,
    .fr-box a.fr-floating-btn svg,
    .fr-box a.fr-floating-btn:hover svg {
        fill: $dark-color;
    }
    .fr-view table th,
    .fr-view table tfoot td,
    .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
        background-color: $dark-theme;
    }
    .fr-view table td,
    .fr-view table th {
        border-color: $dark-border;
    }
    .fr-box a.fr-floating-btn,
    .fr-box a.fr-floating-btn:hover {
        background-color: $dark-theme;
        color: $dark-color;
    }
    .fr-popup .fr-video-upload-layer:hover {
        background-color: $dark-background;
    }
    .fr-toolbar .fr-command.fr-btn,
    .fr-popup .fr-command.fr-btn,
    .fr-modal .fr-command.fr-btn,
    .fr-desktop .fr-command.fr-selected:not(.fr-table-cell),
    .fr-desktop .fr-command:active {
        color: $dark-color;
    }
    .fr-desktop .fr-command:hover,
    .fr-desktop .fr-command:focus,
    .fr-desktop .fr-command.fr-btn-hover,
    .fr-desktop .fr-command.fr-expanded {
        color: $dark-color;
    }
    .fr-popup .fr-file-upload-layer:hover,
    .fr-popup .fr-image-upload-layer:hover {
        background-color: $dark-background;
    }
    .fr-file-size,
    .fr-file-date,
    #fr-logo > span,
    .fr-box .fr-counter {
        color: $white-8;
    }
    .fr-markdown-editor, textarea.fr-code{
        background-color: $dark-theme !important;
        color: $dark-color;
    }
    .gutter-horizontal{
        background-color: $dark-border;
    }
    .e-resize-handler{
        color: $dark-border;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-head, .fr-modal .fr-modal-wrapper{
        background-color: $dark-theme;
    }
    .fr-modal{
        color: $dark-color;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child{
        color: $dark-color;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr, .fr-modal .fr-modal-wrapper .fr-modal-head{
        border-block-end-color: $dark-border;
    }
    .fr-popup .fr-checkbox input:not(:checked)+span{
        border: solid 2px  $dark-border;
    }
}


// TRANSPARENT STYLES //

.transparent-mode {
    .fr-toolbar,
    .fr-box.fr-basic .fr-wrapper,
    .fr-second-toolbar {
        background-color: $dark-background !important;
    }
    .fr-toolbar .fr-command.fr-btn svg path,
    .fr-popup .fr-command.fr-btn svg path,
    .fr-modal .fr-command.fr-btn svg path {
        fill: $dark-color !important;
    }
    .fr-toolbar,
    .fr-second-toolbar,
    .fr-box.fr-basic .fr-wrapper {
        border-color: $transparent-border !important;
    }
    .fr-desktop .fr-command:hover:not(.fr-table-cell),
    .fr-desktop .fr-command:focus:not(.fr-table-cell),
    .fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
    .fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
        background-color: $transparent-theme !important;
    }
    .fr-toolbar .fr-more-toolbar {
        background-color: $transparent-theme !important;
    }
    .fr-box.fr-basic .fr-element {
        color: $dark-color !important;
    }
    .fr-command.fr-btn + .fr-dropdown-menu {
        background-color: $dark-background !important;
    }
    .fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
        background-color: $transparent-theme !important;
    }
    .fr-toolbar {
        color: $dark-color;
    }
    .fr-command.fr-btn
        + .fr-dropdown-menu
        .fr-dropdown-wrapper
        .fr-dropdown-content
        ul.fr-dropdown-list
        li
        a.fr-active {
        background-color: $transparent-theme !important;
    }
    .fr-popup {
        background-color: $dark-background !important;
        color: $dark-color;
    }
    .fr-trim-button,
    .fr-popup .fr-buttons.fr-tabs,
    .fr-popup .fr-files-upload-layer:hover {
        background-color: $dark-background !important;
        color: $primary;
    }
    .fr-toolbar .fr-newline {
        background-color: $transparent-border;
    }
    .fr-toolbar .fr-command.fr-btn.fr-btn-active-popup,
    .fr-popup .fr-command.fr-btn.fr-btn-active-popup,
    .fr-modal .fr-command.fr-btn.fr-btn-active-popup {
        background-color: $dark-background;
    }
    .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
    .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
    .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
        background-color: $dark-background;
    }
    .fr-popup .fr-input-line input[type="text"],
    .fr-popup .fr-input-line input[type="number"],
    .fr-popup .fr-input-line textarea {
        border-color: $transparent-border;
        background-color: $transparent-theme;
        color: $dark-color;
    }
    .fr-popup .fr-input-line input + label,
    .fr-popup .fr-input-line textarea + label {
        color: $dark-color;
        background-color: transparent;
    }
    .fr-popup .fr-action-buttons button.fr-command {
        color: $white-8;
    }
    .fr-popup .fr-input-line input[type="text"]:focus,
    .fr-popup .fr-input-line input[type="number"]:focus,
    .fr-popup .fr-input-line textarea:focus {
        border-color: $transparent-border;
    }
    .fr-qi-helper a.fr-btn.fr-floating-btn {
        background-color: $dark-background;
    }
    .fr-qi-helper a.fr-btn.fr-floating-btn svg {
        fill: $dark-color;
    }
    .fr-view table td,
    .fr-view table th {
        border-color: $transparent-border;
    }
    .fr-element table td.fr-selected-cell,
    .fr-element table th.fr-selected-cell {
        border-color: $primary;
    }
    .fr-separator {
        background-color: $transparent-border;
    }
    .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
    .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
    .fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
        background-color: $dark-background !important;
    }
    .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a svg path,
    .fr-box a.fr-floating-btn svg,
    .fr-box a.fr-floating-btn:hover svg {
        fill: $dark-color;
    }
    .fr-view table th,
    .fr-view table tfoot td,
    .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
        background-color: $transparent-theme;
    }
    .fr-view table td,
    .fr-view table th {
        border-color: $transparent-border;
    }
    .fr-box a.fr-floating-btn,
    .fr-box a.fr-floating-btn:hover {
        background-color: $dark-background;
        color: $dark-color;
    }
    .fr-popup .fr-video-upload-layer:hover {
        background-color: $dark-background;
    }
    .fr-toolbar .fr-command.fr-btn,
    .fr-popup .fr-command.fr-btn,
    .fr-modal .fr-command.fr-btn,
    .fr-desktop .fr-command.fr-selected:not(.fr-table-cell),
    .fr-desktop .fr-command:active {
        color: $dark-color;
    }
    .fr-desktop .fr-command:hover,
    .fr-desktop .fr-command:focus,
    .fr-desktop .fr-command.fr-btn-hover,
    .fr-desktop .fr-command.fr-expanded {
        color: $dark-color;
    }
    .fr-popup .fr-file-upload-layer:hover,
    .fr-popup .fr-image-upload-layer:hover {
        background-color: $dark-background;
    }
    .fr-file-size,
    .fr-file-date,
    #fr-logo > span,
    .fr-box .fr-counter {
        color: $white-8;
    }
    .fr-markdown-editor, textarea.fr-code{
        background-color: $transparent-theme !important;
        color: $dark-color;
    }
    .gutter-horizontal{
        background-color: $transparent-border;
    }
    .e-resize-handler{
        color: $transparent-border;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-head, .fr-modal .fr-modal-wrapper{
        background-color: $dark-background;
    }
    .fr-modal{
        color: $dark-color;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody td:first-child{
        color: $dark-color;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table tbody tr, .fr-modal .fr-modal-wrapper .fr-modal-head{
        border-block-end-color: $transparent-border;
    }
    .fr-toolbar .fr-command.fr-btn.fr-dropdown:after, .fr-popup .fr-command.fr-btn.fr-dropdown:after, .fr-modal .fr-command.fr-btn.fr-dropdown:after{
        border-block-start: 4px solid $transparent-border;
    }
    .fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path, .fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path{
        fill: $dark-color !important;
    }
    .fr-popup .fr-action-buttons button.fr-command:hover, .fr-popup .fr-action-buttons button.fr-command:focus{
        color: $dark-color;
    }
    .fr-popup .fr-checkbox input:not(:checked)+span{
        border: solid 2px $transparent-border;
    }
    #fr-logo:hover>span, #fr-logo:focus>span, .fr-trim-button{
        color: $dark-color !important;
    }
}

.rtl{
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal{
        text-align: end;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-head h4 {
        float: $float-end;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-head .fr-modal-close {
        inset-inline-start: 0;
        inset-inline-end: inherit;
    }
    .fr-modal .fr-modal-wrapper .fr-modal-body .fr-help-modal table th {
        text-align: end;
    }
    .fr-box.fr-basic .fr-element, .fr-popup {
        text-align: end;
    }
    .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn{
        text-align: end;
    }
    .fr-toolbar .fr-command.fr-btn span, .fr-popup .fr-command.fr-btn span, .fr-modal .fr-command.fr-btn span{
        float: $float-end;
    }
    .fr-popup .fr-input-line input+label, .fr-popup .fr-input-line textarea+label {
        inset-inline-end: 12px;
        inset-inline-start: inherit;
    }
    .fr-popup .fr-input-line input.fr-not-empty+label, .fr-popup .fr-input-line textarea.fr-not-empty+label{
        inset-inline-end: 4px;
        inset-inline-start: inherit;
    }
    .fr-input-line{
        input{
            text-align: end;
        }
    }
    .fr-quick-insert{
        padding-inline-start: 10px;
        padding-inline-end: inherit;
        inset-inline-start: auto !important;
        inset-inline-end: -30px;
    }
    .fr-qi-helper{
        padding-inline-end: 20px;
        padding-inline-start: inherit;
        inset-inline-start: auto !important;
        inset-inline-end: auto;
    }
    .fr-toolbar{
        text-align: end;
    }
    .fr-toolbar .fr-float-left, #fr-logo{
        float: $float-end;
    }
    .fr-toolbar .fr-btn-grp {
        margin: 0 12px 0 17px;
    }
    .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn, #fr-logo>span{
        float: $float-end;
    }
    .fr-toolbar .fr-float-right, .fr-box .fr-counter{
        float: $float-start;
    }
    .fr-toolbar .fr-more-toolbar{
        float: $float-end;  
    }
    .fr-box.fr-basic .fr-wrapper{
        inset-inline-end: 0;
        inset-inline-start: inherit;
    }
    .fr-command.fr-btn+.fr-dropdown-menu .fr-dropdown-wrapper{
        float: $float-end;
        text-align: end;
    }
    .fr-toolbar .fr-btn-wrap, .fr-popup .fr-btn-wrap, .fr-modal .fr-btn-wrap{
        float: $float-end;
    }
    .fr-command.fr-btn.fr-options{
        margin-inline-end: -5px;
        margin-inline-start: inherit;
    }
    .fr-toolbar .fr-command.fr-btn.fr-dropdown:after, .fr-popup .fr-command.fr-btn.fr-dropdown:after, .fr-modal .fr-command.fr-btn.fr-dropdown:after{
        inset-inline-start: 2px;
        inset-inline-end: inherit;
    }
    .fr-toolbar .fr-command.fr-btn.fr-dropdown i, .fr-toolbar .fr-command.fr-btn.fr-dropdown span, .fr-toolbar .fr-command.fr-btn.fr-dropdown img, .fr-toolbar .fr-command.fr-btn.fr-dropdown svg, .fr-popup .fr-command.fr-btn.fr-dropdown i, .fr-popup .fr-command.fr-btn.fr-dropdown span, .fr-popup .fr-command.fr-btn.fr-dropdown img, .fr-popup .fr-command.fr-btn.fr-dropdown svg, .fr-modal .fr-command.fr-btn.fr-dropdown i, .fr-modal .fr-command.fr-btn.fr-dropdown span, .fr-modal .fr-command.fr-btn.fr-dropdown img, .fr-modal .fr-command.fr-btn.fr-dropdown svg{
        margin-inline-end: 3px;
        margin-inline-start: 11px;
    }
    .fr-box a.fr-floating-btn.fr-btn+.fr-btn {
        margin-inline-end: 10px;
        margin-inline-start: inherit;
    }    
    .fr-box a.fr-floating-btn{
        inset-inline-end: 0;
        inset-inline-start: inherit;
    }
}
