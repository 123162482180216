.logo-container {
    inset-inline-start: 50px;
    position: absolute;
    inset-block-start: 20px;
    z-index: 3;

    .logo {
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #333333;
        width: 60px;
        float: $float-start;
    }

    .brand {
        font-size: 18px;
        color: $white;
        line-height: 20px;
        float: $float-start;
        margin-inline-start: 10px;
        margin-block-start: 10px;
        width: 60px;
    }
}

.section .wizard-card {
    min-height: inherit;
}

.tim-row {
    margin-block-end: 20px;
}

.tim-white-buttons {
    background-color: #777777;
}

.title {
    margin-block-start: 30px;
    margin-block-end: 25px;
    min-height: 32px;
    font-weight: 500;

    &.text-center {
        margin-block-end: 50px;
    }
}

.tim-typo {
    padding-inline-start: 25%;
    margin-block-end: 40px;
    position: relative;

    .tim-note {
        inset-block-end: 10px;
        color: #c0c1c2;
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        inset-inline-start: 0;
        margin-inline-start: 20px;
        position: absolute;
        width: 260px;
    }
}

.tim-row {
    padding-block-start: 50px;

    h3 {
        margin-block-start: 0;
    }
}

.switch {
    margin-inline-end: 20px;
}

#navbar-full .navbar {
    border-radius: 0 !important;
    margin-block-end: 15px;
    z-index: 2;
}

.space {
    height: 130px;
    display: block;
}

.space-110 {
    height: 110px;
    display: block;
}

.space-50 {
    height: 50px;
    display: block;
}

.space-70 {
    height: 70px;
    display: block;
}

.navigation-example {
    .img-src {
        background-attachment: scroll;
    }
    background-position: center center;
    background-size: cover;
    margin-block-start: 0;
    min-height: 740px;
}

#notifications {
    background-color: $white;
    display: block;
    width: 100%;
    position: relative;

    .alert-danger {
        margin-block-end: 0px;
    }
}

.tim-note {
    text-transform: capitalize;
}

#buttons .btn {
    margin: 0 0px 15px;
}

.space-100 {
    height: 100px;
    display: block;
    width: 100%;
}

.be-social {
    padding-block-end: 20px;
    margin: 0 auto 40px;
}

.txt-white {
    color: $white;
}

.txt-gray {
    color: #ddd !important;
}

.parallax {
    width: 100%;
    height: 570px;
    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.logo-container {
    &.logo-documentation {
        position: relative;
        inset-block-start: 0;
        inset-inline-start: 0;
    }

    .logo {
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid #333333;
        width: 50px;
        float: $float-start;
    }

    .brand {
        font-size: 16px;
        line-height: 18px;
        float: $float-start;
        margin-inline-start: 10px;
        margin-block-start: 7px;
        width: 70px;
        height: 40px;
        text-align: start;
    }
}

.navbar-default .logo-container .brand {
    color: #999999;
}

.navbar-transparent .logo-container .brand {
    color: $white;
}

.logo-container {
    .brand-material {
        font-size: 18px;
        margin-block-start: 15px;
        height: 25px;
        width: auto;
    }

    .logo img {
        width: 100%;
    }
}

.navbar-small .logo-container .brand {
    color: #333333;
}

.fixed-section {
    inset-block-start: 90px;
    max-height: 80vh;
    overflow: scroll;

    ul li {
        list-style: none;
    }

    li a {
        font-size: 14px;
        padding: 2px;
        display: block;
        color: #666666;

        &.active {
            color: #00bbff;
        }
    }

    &.float {
        position: fixed;
        inset-block-start: 100px;
        width: 200px;
        margin-block-start: 0;
    }
}

.parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;

    img {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .parallax .parallax-image {
        width: 100%;
        height: 640px;
        overflow: hidden;

        img {
            height: 100%;
            width: auto;
        }
    }
}

.separator {
    content: "Separator";
    color: $white;
    display: block;
    width: 100%;
    padding: 20px;
}

.separator-line {
    background-color: #eee;
    height: 1px;
    width: 100%;
    display: block;
}

.separator.separator-gray {
    background-color: #eeeeee;
}

.social-buttons-demo .btn {
    margin-inline-end: 5px;
    margin-block-end: 7px;
}

.img-container {
    width: 100%;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.lightbox {
    img {
        width: 100%;
    }

    .modal-content {
        overflow: hidden;
    }

    .modal-body {
        padding: 0;
    }
}

@media screen and (min-width: 991px) {
    .lightbox .modal-dialog {
        width: 960px;
    }
}

@media (max-width: 768px) {

    .parallax .motto {
        inset-block-start: 170px;
        margin-block-start: 0;
        font-size: 60px;
        width: 270px;
    }
}

/*       Loading dots  */

/*      transitions */

.presentation .front {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    &:after,
    .btn {
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }
}

.logo-container {
    .logo,
    .brand {
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }
}

#images h4 {
    margin-block-end: 30px;
}

#javascriptComponents {
    padding-block-end: 0;

    .btn-raised {
        margin: 10px 5px;
    }
}

/*      layer animation          */

.layers-container {
    display: block;
    margin-block-start: 50px;
    position: relative;

    img {
        position: absolute;
        width: 100%;
        height: auto;
        inset-block-start: 0;
        inset-inline-start: 0;
        text-align: center;
    }
}

.section-black {
    background-color: #333;
}

.animate {
    transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
    color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
    color: $white;
}

.navbar-default.navbar-small .logo-container .brand {
    color: #333333;
}

.sharing-area {
    margin-block-start: 80px;

    .btn {
        margin: 15px 4px 0;
        color: $white;

        i {
            font-size: 18px;
            position: relative;
            inset-block-start: 2px;
            margin-inline-end: 5px;
        }
    }

    .btn-twitter {
        background-color: #55acee;
    }

    .btn-facebook {
        background-color: #3b5998;
    }

    .btn-google-plus {
        background-color: #dd4b39;
    }

    .btn-github {
        background-color: #333333;
    }
}

.section-thin,
.section-notifications {
    padding: 0;
}

.section-navbars {
    padding-block-start: 0;
}

#navbar .navbar {
    border-radius: 0;
}

.section-tabs {
    background: #eeeeee;
}

.section-pagination {
    padding-block-end: 0;
}

.section-download h4 {
    margin-block-end: 50px;
}

.section-examples {
    a {
        text-decoration: none;
    }

    h5 {
        margin-block-start: 30px;
    }
}

.components-page .wrapper > .header,
.tutorial-page .wrapper > .header {
    height: 400px;
    padding-block-start: 100px;
    background-size: cover;
    background-position: center center;
}

.components-page .title,
.tutorial-page .title {
    color: $white;
}

.main {
    position: relative;
    z-index: 3;
}

.main-raised {
    margin: -60px 30px 0px;
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px $black-2;
}

.header-filter {
    position: relative;

    &:before,
    &:after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        inset-inline-start: 0;
        inset-block-start: 0;
        content: "";
    }

    &::before {
        background-color: $black-4;
    }

    .container {
        z-index: 2;
        position: relative;
    }
}

.section {
    padding: 70px 0;
    background-position: center center;
    background-size: cover;
}

.navbar {
    .navbar-brand {
        position: relative;
        height: 50px;
        line-height: 30px;
        color: inherit;
        padding: 10px 15px;

        &:hover,
        &:focus {
            color: inherit;
            background-color: transparent;
        }
    }

    &.navbar-transparent {
        background-color: transparent;
        box-shadow: none;
        color: $white;
        padding-block-start: 25px;
    }
}

.navbar-fixed-top {
    border-radius: 0;
}

.navbar .navbar-nav > li > a {
    .material-icons,
    .fa {
        font-size: 20px;
        max-width: 20px;
    }

    &:hover,
    &:focus {
        color: inherit;
        background-color: transparent;
    }

    &:not(.btn) .material-icons {
        margin-block-start: -3px;
        inset-block-start: 0px;
        position: relative;
        margin-inline-end: 3px;
    }

    color: inherit;
    padding-block-start: 15px;
    padding-block-end: 15px;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
}

@media (max-width: 1199px) {
    .navbar {
        .navbar-brand {
            height: 50px;
            padding: 10px 15px;
        }

        .navbar-nav > li > a {
            padding-block-start: 15px;
            padding-block-end: 15px;
        }
    }
}

:placeholder {
    color: $white;
}

footer {
    &.footer-documentation {
        margin-block-start: 0;
        inset-block-end: 0;
        text-shadow: none;
        color: inherit;

        li a {
            color: inherit;

            &:hover,
            &:focus {
                color: #89229b;
            }
        }
    }

    ul {
        margin-block-end: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            a {
                color: inherit;
                padding: 15px;
                font-weight: 500;
                font-size: 12px;
                text-transform: uppercase;
                border-radius: 3px;
                text-decoration: none;
                position: relative;
                display: block;

                &:hover {
                    text-decoration: none;
                }
            }

            .btn {
                margin: 0;
            }
        }

        &.links-horizontal {
            &:first-child a {
                padding-inline-start: 0;
            }

            &:last-child a {
                padding-inline-end: 0;
            }
        }

        &.links-vertical li {
            display: block;

            a {
                padding: 5px 0;
            }
        }
    }

    .social-buttons {
        a,
        .btn {
            margin-block-start: 5px;
            margin-block-end: 5px;
        }
    }

    .footer-brand {
        float: $float-start;
        height: 50px;
        padding: 15px 15px;
        font-size: 18px;
        line-height: 20px;
        margin-inline-start: -15px;

        &:hover,
        &:focus {
            color: #3c4858;
        }
    }

    .copyright {
        padding: 15px 0;
        text-align: center;

        .material-icons {
            font-size: 18px;
            position: relative;
            inset-block-start: 3px;
        }
    }

    .pull-center {
        display: inline-block;
        float: none;
    }
}

@media (max-width: 768px) {
    .footer .copyright {
        display: inline-block;
        text-align: center;
        padding: 10px 0;
        float: none !important;
        width: 100%;
    }

    .navbar.navbar-transparent {
        background-color: $black-4;
        padding-block-start: 10px;
        border-radius: 0;
    }

    .main-raised {
        margin-inline-start: 10px;
        margin-inline-end: 10px;
    }
}
